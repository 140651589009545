import { type ChangeEvent, useCallback, useEffect, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import type {
  TransactionEdit_transaction_OrganizationTransaction_pointsOfContact,
  TransactionEdit_transaction_OrganizationTransaction_titleAgency,
  TransactionEdit_transaction_OrganizationTransaction_recordingLocation,
  TransactionEdit_organization_Organization,
} from "lender_portal/transactions/edit/transaction_edit_query.graphql";
import { DeprecatedTextField } from "common/form/fields/text";
import { DeprecatedFirstNameField, DeprecatedLastNameField } from "common/form/fields/name_fields";
import { DeprecatedPhoneNumberField } from "common/form/fields/phone/number";
import { DeprecatedFormRow } from "common/form/elements/row";
import {
  type TitleAgentLookup,
  TitleLookupErrors,
  EmailAddressEventTypes,
} from "common/mortgage/transactions/edit/title_agency_service";
import FormGroupErrors from "common/form/group_errors";
import SectionHeader from "common/form/sub_form/section/header";
import { HeaderAction } from "common/form/sub_form/section/header_action";
import ShownToSignerRow from "common/mortgage/transactions/form/v2/points_of_contact/point_of_contact/shown_to_signer_row";
import AccessToTransactionRow from "common/mortgage/transactions/form/v2/points_of_contact/point_of_contact/access_to_transaction_row";
import { DeprecatedMultipartRow } from "common/form/inputs/multipart/row";
import { DeprecatedMultipartColumn } from "common/form/inputs/multipart/column";
import { useTitleAgencyAutocompleteService } from "common/mortgage/transactions/edit/title_collaborator/title_agency_autocomplete_service";

import TitleAgencySelection from "./title_agency_selection";
import CollaboratorRoleSelection from "./collaborator_role_selection";
import Styles from "./index.module.scss";

type Props = {
  onEmailChange: (arg: string, index?: number | null) => void;
  titleAgentLookup: TitleAgentLookup | null;
  fieldNamePrefix: string;
  pointOfContact: TransactionEdit_transaction_OrganizationTransaction_pointsOfContact;
  recordingLocation?: TransactionEdit_transaction_OrganizationTransaction_recordingLocation;
  onFieldChange: (field: string, arg: unknown) => void;
  index: number;
  isFullRON: boolean;
  isOtherTransactionType: boolean;
  initialTitleAgency: TransactionEdit_transaction_OrganizationTransaction_titleAgency;
  onClickRemove: (arg: number) => void | null;
  titleAgency: string | null;
  organization: TransactionEdit_organization_Organization;
};

function getOnboardedStatusDescription(
  email: string | null,
  titleAgentLookup: TitleAgentLookup | null,
  currentTitleAgency: string | null,
  onApplyTitleAgentLookup: (titleAgentLookup: TitleAgentLookup) => void,
) {
  if (email && titleAgentLookup && !titleAgentLookup.error) {
    return (
      <>
        <FormattedMessage
          id="6eac38d5-dcdc-4a76-a69c-f6192f480a63"
          defaultMessage="We found a title agency or business associated with this email."
        />{" "}
        {currentTitleAgency !== titleAgentLookup.titleAgencyId && (
          <a
            className={Styles.nonOnboardedLink}
            onClick={() => onApplyTitleAgentLookup(titleAgentLookup)}
          >
            <FormattedMessage id="53cea86d-0d6e-4ba5-827a-eb3ed08ad48c" defaultMessage="Apply" />
          </a>
        )}
      </>
    );
  } else if (email && titleAgentLookup && titleAgentLookup.error === TitleLookupErrors.NOT_FOUND) {
    return (
      <>
        <FormattedMessage
          id="92d9d76f-2a38-44b4-aada-54b76ad6fa5c"
          defaultMessage="We haven't worked with this collaborator yet!"
        />{" "}
        <div>
          <FormattedMessage
            id="d1252c23-7e28-4907-ad8b-a3956ae30d1f"
            defaultMessage="Please add their details below."
          />
        </div>
      </>
    );
  }
}

const TitleCollaborator = ({
  onEmailChange,
  titleAgentLookup,
  fieldNamePrefix,
  pointOfContact,
  onFieldChange,
  isFullRON,
  index,
  initialTitleAgency,
  isOtherTransactionType,
  onClickRemove,
  recordingLocation,
  titleAgency,
  organization,
}: Props) => {
  const intl = useIntl();
  const isPrimary = index === 0;

  const {
    selectedTitleAgency,
    setSelectedTitleAgency,
    setAutocompleteInput,
    handleAutocompleteChange,
    handleTitleAgencyChange,
    autocompleteOptions,
    autocompleteLoading,
  } = useTitleAgencyAutocompleteService(
    initialTitleAgency,
    isFullRON,
    recordingLocation?.usState!.id ?? null,
  );

  const setTitleAgentLookupInAutocompleteCache = useCallback(
    (titleAgentLookup: TitleAgentLookup) => {
      const lookupOption = {
        id: titleAgentLookup.titleAgencyId!,
        name: titleAgentLookup.titleAgencyName!,
        isSetup: {
          eligibilityComplete: titleAgentLookup.isSetup!,
        },
        address: {
          line1: titleAgentLookup.address?.line1 || "",
          city: titleAgentLookup.address?.city || "",
          state: titleAgentLookup.address?.state || "",
        },
      };
      setSelectedTitleAgency(lookupOption);
    },
    [titleAgentLookup],
  );

  const onApplyTitleAgentLookup = useCallback(
    (titleAgentLookup: TitleAgentLookup | null) => {
      if (titleAgentLookup && !titleAgentLookup.error) {
        onFieldChange("titleAgency", titleAgentLookup.titleAgencyId);
        setTitleAgentLookupInAutocompleteCache(titleAgentLookup);
      }
    },
    [onFieldChange, setTitleAgentLookupInAutocompleteCache],
  );

  useEffect(() => {
    // title agent lookup auto-sets the title agency field so we must set it in cache
    // so it is available in react-select's options list.
    // we don't, however, want to do the above on the initial lookup
    // because the initialTitleAgency should be set in cache instead.
    if (
      titleAgentLookup &&
      !titleAgentLookup.error &&
      titleAgentLookup.type === EmailAddressEventTypes.CHANGE
    ) {
      setTitleAgentLookupInAutocompleteCache(titleAgentLookup);
    }
  }, [titleAgentLookup]);

  const isSharedInboxEmail = useMemo(() => {
    return !!(
      titleAgentLookup &&
      !titleAgentLookup.error &&
      titleAgentLookup.inboxEmail?.toLowerCase() === pointOfContact.email?.toLowerCase()
    );
  }, [titleAgentLookup, pointOfContact]);

  return (
    <div className={Styles.titleCollaborator}>
      <SectionHeader
        action={
          !isPrimary ? (
            <HeaderAction onClick={() => onClickRemove(index)} automationId="remove-collaborator">
              <FormattedMessage id="91585416-4fcf-48c9-8e8a-4f0b2cc8bd15" defaultMessage="Remove" />
            </HeaderAction>
          ) : null
        }
        title="Collaborator"
      />
      <p className={Styles.subtitle}>
        <FormattedMessage
          id="d15c589d-bd81-40b9-9ae4-fa35e48575ff"
          defaultMessage="Collaborators can upload documents to the closing package and view completed documents"
        />
      </p>
      <DeprecatedFormRow>
        <CollaboratorRoleSelection
          fieldName={`${fieldNamePrefix}.role`}
          onClick={onFieldChange}
          value={pointOfContact.role}
        />
      </DeprecatedFormRow>
      <DeprecatedFormRow>
        <DeprecatedTextField
          data-automation-id="TitleCollaborator-email"
          name={`${fieldNamePrefix}.email`}
          placeholder="Email address"
          placeholderAsLabel
          useStyledInput
          onChange={(evt: ChangeEvent<HTMLInputElement>) => {
            isPrimary && onEmailChange(evt.target.value, index);
          }}
        />
        <FormGroupErrors fields={[`${fieldNamePrefix}.email`]} />
      </DeprecatedFormRow>
      <div className={Styles.onboardStatus}>
        {isPrimary &&
          getOnboardedStatusDescription(
            pointOfContact.email,
            titleAgentLookup,
            titleAgency,
            onApplyTitleAgentLookup,
          )}
      </div>
      <>
        <DeprecatedFormRow>
          <DeprecatedMultipartRow>
            <DeprecatedMultipartColumn width={6}>
              <DeprecatedFirstNameField
                name={`${fieldNamePrefix}.firstName`}
                useStyledInput
                placeholderAsLabel
                displayRequiredAsterisk={Boolean(pointOfContact.email)}
                automationId="TitleCollaborator-first-name"
              />
            </DeprecatedMultipartColumn>

            <DeprecatedMultipartColumn width={6}>
              <DeprecatedLastNameField
                name={`${fieldNamePrefix}.lastName`}
                useStyledInput
                placeholderAsLabel
                displayRequiredAsterisk={Boolean(pointOfContact.email)}
                automationId="TitleCollaborator-last-name"
              />
            </DeprecatedMultipartColumn>
          </DeprecatedMultipartRow>
          <FormGroupErrors
            fields={[`${fieldNamePrefix}.firstName`, `${fieldNamePrefix}.lastName`]}
          />
        </DeprecatedFormRow>
        <DeprecatedFormRow>
          <DeprecatedPhoneNumberField
            name={`${fieldNamePrefix}.phoneNumber`}
            data-automation-id="organizationTransactionWitnesses-phone-number-field"
            label={intl.formatMessage({
              id: "69ff26f3-fdb7-45e8-b81c-1d4ae246afed",
              defaultMessage: "Phone",
            })}
          />
        </DeprecatedFormRow>
      </>
      {isPrimary && (
        <TitleAgencySelection
          titleAgentLookup={titleAgentLookup}
          pointOfContact={pointOfContact}
          onAddressRemove={() => {
            onFieldChange(`${fieldNamePrefix}.organizationAddress`, null);
          }}
          isFullRON={isFullRON}
          initialTitleAgency={initialTitleAgency}
          onClearAgencyInput={() => {
            onFieldChange(`${fieldNamePrefix}.organizationName`, null);
            onFieldChange(`${fieldNamePrefix}.organizationAddress`, null);
          }}
          onClearAgencySelect={() => {
            onFieldChange("titleAgency", "");
          }}
          selectedTitleAgency={selectedTitleAgency}
          handleAutocompleteChange={handleAutocompleteChange}
          handleTitleAgencyChange={handleTitleAgencyChange}
          setAutocompleteInput={setAutocompleteInput}
          autocompleteLoading={autocompleteLoading}
          autocompleteOptions={autocompleteOptions}
          isSharedInboxEmail={isSharedInboxEmail}
          organization={organization}
        />
      )}
      <DeprecatedFormRow>
        <ShownToSignerRow fieldNamePrefix={fieldNamePrefix} />
      </DeprecatedFormRow>
      {!(isFullRON || isOtherTransactionType) && (
        <DeprecatedFormRow>
          <AccessToTransactionRow fieldNamePrefix={fieldNamePrefix} />
        </DeprecatedFormRow>
      )}
    </div>
  );
};

export default TitleCollaborator;
