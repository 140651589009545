import "./index.scss";
import { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { defineMessages, injectIntl } from "react-intl";
import { FieldArray } from "redux-form";

import { segmentTrack } from "util/segment";
import { EVENT } from "constants/analytics";
import { DeprecatedRadioButtonField } from "common/form/fields/radio";
import TipWell from "common/core/tip_well";
import Button from "common/core/button";
import Icon from "common/core/icon";
import SubForm from "common/form/sub_form";
import SubFormSection from "common/form/sub_form/section";
import SubFormSectionDivider from "common/form/sub_form/section/divider";
import SectionHeader from "common/form/sub_form/section/header";
import { HeaderAction } from "common/form/sub_form/section/header_action";
import { DeprecatedFormRow } from "common/form/elements/row";
import AddressSubForm, {
  ADDRESS_FIELDS,
  validationRules as addressValidationRules,
  fields as addressFields,
} from "common/form/sub_forms/address";
import { deprecatedSubForm } from "common/form/enhancers/sub_form";
import FormGroup from "common/form/group";
import FormGroupErrors from "common/form/group_errors";
import { DeprecatedPhoneCountryCodeField } from "common/form/fields/phone/country_code";
import { deprecatedIsInternational } from "common/form/inputs/phone/country_code";
import { DeprecatedPhoneNumberField } from "common/form/fields/phone/number";
import { DeprecatedMultipartRow } from "common/form/inputs/multipart/row";
import { DeprecatedMultipartColumn } from "common/form/inputs/multipart/column";
import DeleteCustomerSignerFromTransactionModal from "common/transactions/delete_customer_signer_from_transaction_modal";
import { DeprecatedCheckboxField } from "common/form/fields/checkbox";
import Tooltip from "common/core/tooltip";
import { composeValidators, composeAsyncValidators } from "util/form";
import { validateIf, validateIfAny, validatePresence } from "validators/form";
import { validateEmailFormat } from "validators/account";
import { Feature, AuthTypes, VestingTypesEnum } from "graphql_globals";
import SigningGroupSection from "common/form/sub_forms/signing_group";

import {
  duplicateEmailValidationRules,
  asyncValidateCustomerSigners,
  validateCustomerSigners,
} from "./validate";
import CredibleWitnessSection from "../credible_witness";
import CustomerSigner from "./customer_signer";
import RepresentativeSignersSection, {
  validateAllRepresentativeSigners,
} from "../representative_signers";

const messages = defineMessages({
  primarySignerLabel: {
    id: "85c7743e-4653-4de5-a242-d54e85bf8002",
    defaultMessage: "Primary Signer",
  },
  additionalSignersLabel: {
    id: "73de5b93-0257-4c6a-8f26-e0389c345972",
    defaultMessage: "Additional Signer",
  },
  showAddress: {
    id: "7b8682bc-d89a-4f10-9cb5-2e13d5dd068c",
    defaultMessage: "+ Add signer address",
  },
  addOrganizationTransactionWitnesses: {
    id: "787b0501-f2fd-420a-b114-9fa7af487dbc",
    defaultMessage: "+ Add a Credible Witness",
  },
  removeAddress: {
    id: "d569c643-26eb-4654-badb-1596ba709647",
    defaultMessage: "- Remove address",
  },
  additionalSignersRequired: {
    id: "13749e96-5e00-400f-bbd1-1b78b6f4b363",
    defaultMessage: "Does this transaction require additional signers?",
  },
  additionalSignersRequiredSubtitle: {
    id: "48c3362f-c4ee-4e09-a80a-a547c1a463f6",
    defaultMessage:
      "Do any of the documents you're sending require signatures from more than 1 person? " +
      "This does not include witnesses.",
  },
  secondaryId: {
    id: "29dd7895-6452-4582-b9f1-eda0912fd052",
    defaultMessage: "Will the signer(s) need a secondary ID?",
  },
  secondaryIdTipHeader: {
    id: "90a296ee-b496-4928-bff3-f2c8f0b6460a",
    defaultMessage: "When should I ask for a secondary ID?",
  },
  secondaryIdTip: {
    id: "3a4876d4-f854-415a-a692-09cca0975742",
    defaultMessage:
      "Some documents explicitly require two forms of ID. Check the documents you are sending to see if they do.",
  },
  countryCode: {
    id: "da5af457-5cde-4d6f-bf5b-ac1fb1315fe1",
    defaultMessage: "Country Code",
  },
  phoneNumber: {
    id: "c5ad5130-739f-4c6b-b52a-7105b3469322",
    defaultMessage: "Phone Number",
  },
  no: {
    id: "58f712b9-a5c4-4a69-bdea-cf0aeae3c316",
    defaultMessage: "No",
  },
  yes: {
    id: "9c4dde40-9d5c-41c4-b558-92d5e8ec9b00",
    defaultMessage: "Yes",
  },
  additionalSigner: {
    id: "98e8c260-90d9-438f-b425-936e7acd667b",
    defaultMessage: "Additional Signer",
  },
  addAdditionalSigner: {
    id: "02c77b7d-a799-4f05-abe2-7e7b82a3217a",
    defaultMessage: "+ Add additional signer",
  },
  additionalSignerRemove: {
    id: "f00b2cdd-c948-4b92-81e2-a7583cba0d40",
    defaultMessage: "- Remove",
  },
  additionalSignerDisabled: {
    id: "27374d1d-8a76-47ef-ba1b-80608df633ea",
    defaultMessage:
      "You have selected 'Yes' to indicate that the notary personally knows the signer. We only support this feature for transactions with one signer. Change your response to 'No' to add additional signers.",
  },
  requireSmsAuthentication: {
    id: "87710e31-e2f0-4cf9-b335-8a89b7310bbd",
    defaultMessage: "Require SMS Authentication",
  },
  smsAuthenticationTooltip: {
    id: "b83c698e-a5d6-40e8-a061-fbd7e610b2c8",
    defaultMessage:
      "The signer will need to verify their identity by entering an authentication code sent to their mobile phone.",
  },
  tooltipTriggerLabel: {
    id: "311a693d-c4cc-48c4-abe0-e85ad2115573",
    defaultMessage: "More details about SMS Authentication",
  },
});

export const validationRules = (values, props) => {
  const { transaction } = props;
  return composeValidators(
    validateIf({
      field: "customerSigners",
      condition: () => transaction.forcedSequentialSigning,
      validation: duplicateEmailValidationRules,
    }),
    validateCustomerSigners,
    validateAllRepresentativeSigners,
    validatePresence({
      field: "organizationTransactionWitnesses[0].firstName",
      label: "First name",
    }),
    validatePresence({ field: "organizationTransactionWitnesses[0].lastName", label: "Last name" }),
    validateEmailFormat({ field: "organizationTransactionWitnesses[0].email", label: "" }),
    validateIfAny({
      fields: addressFields("customerSigners[0].address."),
      condition: (val) => val,
      validation: addressValidationRules(values || {}, props, "customerSigners[0].address."),
    }),
    validateIf({
      field: "customerSigners[0].phone.number",
      condition: () => values.smsAuthenticationRequired,
      validation: validatePresence({
        field: "customerSigners[0].phone.number",
        label: "Phone Number",
      }),
    }),
    validateIf({
      field: "customerSigners[0].phone.countryCode",
      condition: () => values.smsAuthenticationRequired,
      validation: validatePresence({
        field: "customerSigners[0].phone.countryCode",
        label: "Country Code",
      }),
    }),
  );
};

export const asyncValidationRules = (_values, _props) => {
  return composeAsyncValidators(asyncValidateCustomerSigners);
};

// NOTE: make sure this is defined outside the same class that uses FieldArray
// otherwise every render will cause the fields to re-register
// https://github.com/erikras/redux-form/issues/2412
class CustomerSignersSectionBase extends PureComponent {
  state = {
    customerSignerIndexBeingDeleted: null,
    processingDeleteCustomerSigner: false,
  };

  addSignerAddress = (signerIndex) => {
    segmentTrack(EVENT.ORGANIZATION_TRANSACTION_EDITOR_ADD_SIGNER_ADDR, {
      signerIndex,
    });

    this.props.change(`customerSigners[${signerIndex}].address`, {});
  };

  removeSignerAddress = (signerIndex) => {
    segmentTrack(EVENT.ORGANIZATION_TRANSACTION_EDITOR_REMOVE_SIGNER_ADDR, {
      signerIndex,
    });
    this.props.change(`customerSigners[${signerIndex}].address`, null);
  };

  addSigner = () => {
    const { fields, maxSigners } = this.props;
    if (fields.length < maxSigners) {
      segmentTrack(EVENT.ORGANIZATION_TRANSACTION_EDITOR_ADD_COSIGNER);
      const defaultValues = this.props.showVestingTypeDropdown
        ? { vestingType: VestingTypesEnum.BORROWER }
        : {};
      this.props.fields.push(defaultValues);
    }
  };

  removeSigner = (index) => {
    const { fields } = this.props;

    const customerSigner = fields.get(index);

    if (!customerSigner) {
      return;
    }

    if (customerSigner.id) {
      this.setState({ customerSignerIndexBeingDeleted: index });
    } else {
      this.removeCustomerSignerFromStore(index);
    }
  };

  confirmDeleteSigner = (index) => {
    return () => {
      const { fields, onDeleteCustomerSigner } = this.props;

      const customerSigner = fields.get(index);

      if (!customerSigner) {
        return;
      }

      this.setState({ processingDeleteCustomerSigner: true });

      onDeleteCustomerSigner(customerSigner.id)
        .then(() => {
          this.removeCustomerSignerFromStore(index);
        })
        .catch(() => {}) // if this catch isn't here, the then function will be executed on failures
        .finally(() => {
          this.setState({
            customerSignerIndexBeingDeleted: null,
            processingDeleteCustomerSigner: false,
          });
        });
    };
  };

  removeCustomerSignerFromStore(index) {
    const { fields } = this.props;

    segmentTrack(EVENT.ORGANIZATION_TRANSACTION_EDITOR_REMOVE_COSIGNER, {
      signerIndex: index,
    });
    fields.remove(index);
  }

  handleCloseDeleteCustomerSignerModal = () => {
    this.setState({ customerSignerIndexBeingDeleted: null });
  };

  render() {
    const {
      fields,
      formName,
      formValues: {
        customerSigners,
        secondaryId,
        organizationTransactionWitnesses,
        smsAuthenticationRequired,
      },
      intl,
      organization: { featureList, defaultAuthenticationRequirement },
      hideAddress,
      hideSecondaryId,
      maxSigners,
      change,
      readOnly,
      showSignatoryCapacity,
      showVestingTypeDropdown,
      isHybrid,
      personallyKnownToNotary,
    } = this.props;

    const { customerSignerIndexBeingDeleted, processingDeleteCustomerSigner } = this.state;

    const [primarySigner] = fields.map((signer) => signer);

    const signerHasAddress = customerSigners?.[0]?.address;

    const showAdvancedTransactionDetails = featureList.includes(
      Feature.ADVANCED_TRANSACTION_CREATION,
    );

    const showSmsCheckbox = defaultAuthenticationRequirement === AuthTypes.SMS;

    const primaryRecipientGroup = customerSigners?.[0]?.recipientGroup;

    const customerSignerBeingDeleted = customerSignerIndexBeingDeleted
      ? fields.get(customerSignerIndexBeingDeleted)
      : null;

    const instructionsTip = readOnly ? null : (
      <TipWell
        className="CustomerSignersSection--SecondaryId--Instructions"
        heading={intl.formatMessage(messages.secondaryIdTipHeader)}
      >
        {intl.formatMessage(messages.secondaryIdTip)}
      </TipWell>
    );

    const showAddPrimarySignerAddressButton =
      primarySigner &&
      !signerHasAddress &&
      showAdvancedTransactionDetails &&
      !hideAddress &&
      !readOnly;
    const showRemovePrimarySignerAddressButton =
      primarySigner &&
      signerHasAddress &&
      showAdvancedTransactionDetails &&
      !hideAddress &&
      !readOnly;
    const showPrimarySignerAddress =
      signerHasAddress && showAdvancedTransactionDetails && !hideAddress;

    const showCredibleWitnessSection =
      !isHybrid &&
      !window.location.pathname.startsWith("/transaction/esign") &&
      featureList.includes(Feature.CREDIBLE_WITNESS);

    const disabledAddressFields = readOnly
      ? [
          ADDRESS_FIELDS.LINE_1,
          ADDRESS_FIELDS.LINE_2,
          ADDRESS_FIELDS.CITY,
          ADDRESS_FIELDS.STATE,
          ADDRESS_FIELDS.POSTAL,
        ]
      : [];
    const hiddenAddressFields = readOnly ? [ADDRESS_FIELDS.COUNTRY] : [];

    const primaryCountryCodeField = `${primarySigner}.phone.countryCode`;
    const primaryPhoneNumberField = `${primarySigner}.phone.number`;

    const smsCheckbox = showSmsCheckbox && (
      <FormGroup fields={["smsAuthenticationRequired"]} disableFormRowStyle>
        <DeprecatedCheckboxField
          containerClassname="CustomerSignersSection"
          automationId="sms-authentication-required"
          name="smsAuthenticationRequired"
          label={intl.formatMessage(messages.requireSmsAuthentication)}
        />
        <Tooltip
          target={<Icon name="question" className={"CustomerSignersSection--TooltipIcon"} />}
          placement="bottom"
          className={"CustomerSignersSection--Tooltip"}
          triggerButtonLabel={intl.formatMessage(messages.tooltipTriggerLabel)}
        >
          {intl.formatMessage(messages.smsAuthenticationTooltip)}
        </Tooltip>
      </FormGroup>
    );

    return (
      <>
        <SubForm className="CustomerSignersSection">
          {primaryRecipientGroup ? (
            <SigningGroupSection
              fieldNamePrefix={primarySigner}
              title={intl.formatMessage(messages.primarySignerLabel)}
              sectionClass="CustomerSignersSection--Signers--signer"
            >
              {smsCheckbox}
            </SigningGroupSection>
          ) : (
            <>
              <SubFormSection
                className="CustomerSignersSection--Signers--signer"
                key={primarySigner}
              >
                {smsCheckbox}

                <DeprecatedFormRow noMargin>
                  <SectionHeader title={intl.formatMessage(messages.primarySignerLabel)} />
                </DeprecatedFormRow>

                <DeprecatedFormRow noMargin>
                  <CustomerSigner
                    fieldNamePrefix={primarySigner}
                    displayRequiredAsterisk
                    readOnly={readOnly}
                    showSignatoryCapacity={showSignatoryCapacity}
                    showVestingTypeDropdown={showVestingTypeDropdown}
                  />
                </DeprecatedFormRow>

                {primarySigner && (
                  <>
                    <DeprecatedFormRow>
                      <FormGroup
                        className="SignerSubForm--FormGroup"
                        errorClassName="SignerSubForm__validationFailed"
                        fields={[primaryCountryCodeField, primaryPhoneNumberField]}
                        disableFormRowStyle
                      >
                        <DeprecatedMultipartRow>
                          <DeprecatedMultipartColumn width={3}>
                            <DeprecatedPhoneCountryCodeField
                              name={primaryCountryCodeField}
                              data-automation-id="phone-country-code-field"
                              label={intl.formatMessage(messages.countryCode)}
                              labelRequiredAsterisk={smsAuthenticationRequired}
                              disabled={readOnly}
                            />
                          </DeprecatedMultipartColumn>
                          <DeprecatedMultipartColumn width={9}>
                            <DeprecatedPhoneNumberField
                              name={primaryPhoneNumberField}
                              data-automation-id="phone-number-field"
                              isInternational={deprecatedIsInternational({
                                countryCode: customerSigners?.[0]?.phone?.countryCode || false,
                              })}
                              label={intl.formatMessage(messages.phoneNumber)}
                              labelRequiredAsterisk={smsAuthenticationRequired}
                              disabled={readOnly}
                            />
                          </DeprecatedMultipartColumn>
                        </DeprecatedMultipartRow>
                      </FormGroup>
                      <FormGroupErrors
                        fields={[primaryCountryCodeField, primaryPhoneNumberField]}
                        groupClassName="SignerSubForm--FormGroup"
                        errorClassName="SignerSubForm--validationMessage"
                      />
                    </DeprecatedFormRow>
                  </>
                )}

                {showAddPrimarySignerAddressButton && (
                  <DeprecatedFormRow>
                    <Button
                      variant="tertiary"
                      buttonColor="action"
                      buttonSize="condensed"
                      onClick={() => this.addSignerAddress(0)}
                    >
                      {intl.formatMessage(messages.showAddress)}
                    </Button>
                  </DeprecatedFormRow>
                )}

                {showPrimarySignerAddress && (
                  <AddressSubForm
                    formName={formName}
                    fieldNamePrefix={`${primarySigner}.address`}
                    useStyledInputs
                    disabledFields={disabledAddressFields}
                    hiddenFields={hiddenAddressFields}
                  />
                )}

                {showRemovePrimarySignerAddressButton && (
                  <DeprecatedFormRow>
                    <Button
                      variant="tertiary"
                      buttonColor="action"
                      onClick={() => this.removeSignerAddress(0)}
                    >
                      {intl.formatMessage(messages.removeAddress)}
                    </Button>
                  </DeprecatedFormRow>
                )}
              </SubFormSection>
              <RepresentativeSignersSection fieldNamePrefix={`${primarySigner}.capacities`} />
            </>
          )}

          <SubFormSectionDivider />

          {fields.length < 2 && (
            <SubFormSection
              className="CustomerSignersSection--AdditionalSigners"
              automationId="additionalSignersForm"
            >
              <DeprecatedFormRow>
                <SectionHeader
                  title={intl.formatMessage(messages.additionalSignersRequired)}
                  subtitle={intl.formatMessage(messages.additionalSignersRequiredSubtitle)}
                />
              </DeprecatedFormRow>
              <DeprecatedFormRow>
                <DeprecatedRadioButtonField
                  name="additionalSigners"
                  labelText={intl.formatMessage(messages.no)}
                  automationId="additional-signers-not-required-radio-button"
                  className="CustomerSignersSection--Fields--RadioButton"
                  labelClassName="CustomerSignersSection--Fields--RadioLabel"
                  radioValue={false}
                  groupValue={fields.length > 1}
                  size="small"
                  onChange={() => this.removeSigner(1)}
                  disabled={readOnly || personallyKnownToNotary}
                />
                <DeprecatedRadioButtonField
                  name="additionalSigners"
                  labelText={intl.formatMessage(messages.yes)}
                  automationId="additional-signers-required-radio-button"
                  className="CustomerSignersSection--Fields--RadioButton"
                  labelClassName="CustomerSignersSection--Fields--RadioLabel"
                  radioValue
                  groupValue={fields.length > 1}
                  size="small"
                  onChange={this.addSigner}
                  disabled={readOnly || personallyKnownToNotary}
                />
              </DeprecatedFormRow>
              {personallyKnownToNotary && (
                <div className="CustomerSignersSection--Warning">
                  <div className="CustomerSignersSection--Warning--Icon">
                    <Icon name="disclaimer" />
                  </div>
                  <div className="CustomerSignersSection--Warning--Text">
                    {intl.formatMessage(messages.additionalSignerDisabled)}
                  </div>
                </div>
              )}
            </SubFormSection>
          )}

          {fields.length > 1 &&
            fields.map((signer, index) => {
              if (index === 0) {
                return null;
              }
              const signerPhoneCountryCode = `${signer}.phone.countryCode`;
              const signerPhoneNumber = `${signer}.phone.number`;
              const additionalRecipientGroup = customerSigners?.[index]?.recipientGroup;

              return additionalRecipientGroup ? (
                <Fragment key={index}>
                  <SigningGroupSection
                    fieldNamePrefix={signer}
                    title={intl.formatMessage(messages.additionalSigner)}
                    sectionClass="CustomerSignersSection--Signers"
                    formRowClass="CustomerSignersSection--Signers--signer"
                  />
                </Fragment>
              ) : (
                <Fragment key={index}>
                  <SubFormSection className="CustomerSignersSection--Signers">
                    <DeprecatedFormRow className="CustomerSignersSection--Signers--signer">
                      <SectionHeader
                        action={
                          <HeaderAction
                            onClick={() => {
                              this.removeSigner(index);
                            }}
                          >
                            {intl.formatMessage(messages.additionalSignerRemove)}
                          </HeaderAction>
                        }
                        title={intl.formatMessage(messages.additionalSigner)}
                      />
                      <CustomerSigner
                        fieldNamePrefix={signer}
                        displayRequiredAsterisk
                        readOnly={readOnly}
                        showSignatoryCapacity={showSignatoryCapacity}
                        showVestingTypeDropdown={showVestingTypeDropdown}
                      />
                      <FormGroup
                        className="SignerSubForm--FormGroup"
                        errorClassName="SignerSubForm__validationFailed"
                        fields={[signerPhoneCountryCode, signerPhoneNumber]}
                        disableFormRowStyle
                      >
                        <DeprecatedFormRow>
                          <DeprecatedMultipartRow>
                            <DeprecatedMultipartColumn width={3}>
                              <DeprecatedPhoneCountryCodeField
                                name={signerPhoneCountryCode}
                                data-automation-id="phone-country-code-field"
                                label={intl.formatMessage(messages.countryCode)}
                                labelRequiredAsterisk={smsAuthenticationRequired}
                                disabled={readOnly}
                              />
                            </DeprecatedMultipartColumn>
                            <DeprecatedMultipartColumn width={9}>
                              <DeprecatedPhoneNumberField
                                name={signerPhoneNumber}
                                data-automation-id="phone-number-field"
                                isInternational={deprecatedIsInternational({
                                  countryCode: customerSigners?.[0]?.phone?.countryCode || false,
                                })}
                                label={intl.formatMessage(messages.phoneNumber)}
                                labelRequiredAsterisk={smsAuthenticationRequired}
                                disabled={readOnly}
                              />
                            </DeprecatedMultipartColumn>
                          </DeprecatedMultipartRow>
                        </DeprecatedFormRow>
                      </FormGroup>
                      <FormGroupErrors
                        fields={[signerPhoneCountryCode, signerPhoneNumber]}
                        groupClassName="SignerSubForm--FormGroup"
                        errorClassName="SignerSubForm--validationMessage"
                      />
                    </DeprecatedFormRow>
                  </SubFormSection>
                  <RepresentativeSignersSection fieldNamePrefix={`${signer}.capacities`} />
                </Fragment>
              );
            })}

          {fields.length > 1 && fields.length < maxSigners && !readOnly && (
            <SubFormSection className="CustomerSignersSection--Signers">
              <DeprecatedFormRow>
                <Button variant="tertiary" buttonColor="action" onClick={this.addSigner}>
                  {intl.formatMessage(messages.addAdditionalSigner)}
                </Button>
              </DeprecatedFormRow>
            </SubFormSection>
          )}

          {showAdvancedTransactionDetails && !hideSecondaryId && (
            <SubFormSection
              className="CustomerSignersSection--SecondaryId"
              tipWell={instructionsTip}
              automationId="secondaryIdForm"
            >
              <DeprecatedFormRow>
                <SectionHeader title={intl.formatMessage(messages.secondaryId)} />
              </DeprecatedFormRow>
              <DeprecatedFormRow>
                <DeprecatedRadioButtonField
                  id="secondaryId"
                  name="secondaryId"
                  labelText={intl.formatMessage(messages.no)}
                  automationId="secondary-id-not-required-radio-button"
                  className="CustomerSignersSection--Fields--RadioButton"
                  labelClassName="CustomerSignersSection--Fields--RadioLabel"
                  radioValue={false}
                  groupValue={!!secondaryId}
                  size="small"
                  onChange={() => {
                    change("secondaryId", false);
                  }}
                  disabled={readOnly}
                />
                <DeprecatedRadioButtonField
                  id="secondaryId"
                  name="secondaryId"
                  labelText={intl.formatMessage(messages.yes)}
                  automationId="secondary-id-required-radio-button"
                  className="CustomerSignersSection--Fields--RadioButton"
                  labelClassName="CustomerSignersSection--Fields--RadioLabel"
                  radioValue
                  groupValue={!!secondaryId}
                  size="small"
                  onChange={() => {
                    change("secondaryId", true);
                  }}
                  disabled={readOnly}
                />
              </DeprecatedFormRow>
            </SubFormSection>
          )}

          {showCredibleWitnessSection && (
            <CredibleWitnessSection
              change={change}
              organizationTransactionWitnesses={organizationTransactionWitnesses}
              readOnly={readOnly}
            />
          )}
        </SubForm>

        {customerSignerBeingDeleted && (
          <DeleteCustomerSignerFromTransactionModal
            customer={customerSignerBeingDeleted}
            onClick={this.confirmDeleteSigner(customerSignerIndexBeingDeleted)}
            onClose={this.handleCloseDeleteCustomerSignerModal}
            loading={processingDeleteCustomerSigner}
          />
        )}
      </>
    );
  }
}

class CustomerSignersSection extends PureComponent {
  render() {
    return (
      <FieldArray {...this.props} name="customerSigners" component={CustomerSignersSectionBase} />
    );
  }
}

CustomerSignersSection.defaultProps = {
  hideAddress: false,
  hideSecondaryId: false,
  isHybrid: false,
};

CustomerSignersSection.propTypes = {
  formName: PropTypes.string.isRequired,
  organization: PropTypes.object.isRequired,
  hideAddress: PropTypes.bool,
  hideSecondaryId: PropTypes.bool,
  onDeleteCustomerSigner: PropTypes.func.isRequired,
  maxSigners: PropTypes.number.isRequired,
  readOnly: PropTypes.bool.isRequired,
  showSignatoryCapacity: PropTypes.bool.isRequired,
  showVestingTypeDropdown: PropTypes.bool,
  isHybrid: PropTypes.bool,
  personallyKnownToNotary: PropTypes.bool,
  // Redux Form
  fields: PropTypes.arrayOf(PropTypes.string),

  // deprecatedSubForm
  initialize: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
};

export default deprecatedSubForm({
  getValuesFor: [
    "customerSigners",
    "secondaryId",
    "organizationTransactionWitnesses",
    "smsAuthenticationRequired",
  ],
})(injectIntl(CustomerSignersSection));
