import "./index.scss";

import { PureComponent } from "react";
import PropTypes from "prop-types";
import { defineMessages, injectIntl, FormattedMessage } from "react-intl";

import { DeprecatedFormRow } from "common/form/elements/row";
import FormGroup from "common/form/group";
import FormGroupErrors from "common/form/group_errors";
import { DeprecatedTextField } from "common/form/fields/text";
import { DeprecatedEmailField } from "common/form/fields/email";
import { DeprecatedSelectField } from "common/form/fields/select";
import {
  DeprecatedFirstNameField,
  DeprecatedLastNameField,
  DeprecatedMiddleNameField,
} from "common/form/fields/name_fields";
import { DeprecatedMultipartRow } from "common/form/inputs/multipart/row";
import { DeprecatedMultipartColumn } from "common/form/inputs/multipart/column";
import { VestingTypesEnum } from "graphql_globals";

const messages = defineMessages({
  vestingType: {
    id: "9cf96f05-27d0-473c-92c0-02f0bc22aef9",
    description: "vestingType",
    defaultMessage: "Vesting Type",
  },
  nonborrower: {
    id: "fcf1191f-c31b-4470-bcfb-47dffdb0177e",
    description: "nonborrower",
    defaultMessage: "Nonborrower",
  },
});

const VESTING_TYPE_LABELS = Object.freeze({
  [VestingTypesEnum.BORROWER]: (
    <FormattedMessage
      id="70368bf3-a012-493b-bd97-aaa150e66351"
      description="borrower"
      defaultMessage="Borrower"
    />
  ),
  [VestingTypesEnum.NON_BORROWER]: (
    <FormattedMessage
      id="75ab01df-135e-4a72-bf9c-e780600d7ff4"
      description="nonborrower"
      defaultMessage="Nonborrower"
    />
  ),
});

const VESTING_TYPE_SELECT_ITEMS = Object.freeze(
  Object.keys(VestingTypesEnum).map((type) => ({
    label: VESTING_TYPE_LABELS[type],
    value: type,
  })),
);

class CustomerSignerSubForm extends PureComponent {
  render() {
    const { intl, showSignatoryCapacity, showVestingTypeDropdown, readOnly } = this.props;

    const fieldNamePrefix = this.props.fieldNamePrefix ? `${this.props.fieldNamePrefix}.` : "";
    const displayRequiredAsterisk = this.props.displayRequiredAsterisk || false;

    const nameRowFields = [
      `${fieldNamePrefix}firstName`,
      `${fieldNamePrefix}middleName`,
      `${fieldNamePrefix}lastName`,
    ];

    return (
      <div className="CustomerSignerSubForm">
        {showVestingTypeDropdown && (
          <DeprecatedFormRow className="SignerVestingType--FormRow">
            <FormGroup
              className="CustomerSignerSubForm--FormGroup"
              errorClassName="CustomerSignerSubForm__validationFailed"
              fields={[`${fieldNamePrefix}vestingType`]}
              disableFormRowStyle
            >
              <DeprecatedSelectField
                id="vestingType"
                name={`${fieldNamePrefix}vestingType`}
                placeholder={intl.formatMessage(messages.vestingType)}
                data-automation-id={`${fieldNamePrefix}vesting-type`}
                useStyledInput
                placeholderAsLabel
                displayRequiredAsterisk
                items={VESTING_TYPE_SELECT_ITEMS}
                disabled={readOnly}
              />
              <FormGroupErrors
                fields={[`${fieldNamePrefix}vestingType`]}
                groupClassName="CustomerSignerSubForm--FormGroup"
                errorClassName="CustomerSignerSubForm--ValidationMessage"
              />
            </FormGroup>
          </DeprecatedFormRow>
        )}

        <DeprecatedFormRow>
          <FormGroup
            className="CustomerSignerSubForm--FormGroup"
            errorClassName="CustomerSignerSubForm__validationFailed"
            fields={nameRowFields}
            disableFormRowStyle
          >
            <DeprecatedMultipartRow>
              <DeprecatedMultipartColumn width={5}>
                <DeprecatedFirstNameField
                  id="firstName"
                  name={`${fieldNamePrefix}firstName`}
                  placeholderAsLabel
                  useStyledInput
                  displayRequiredAsterisk={displayRequiredAsterisk}
                  data-automation-id="first-name-field"
                  disabled={readOnly}
                />
              </DeprecatedMultipartColumn>
              <DeprecatedMultipartColumn width={3}>
                <DeprecatedMiddleNameField
                  id="middleName"
                  name={`${fieldNamePrefix}middleName`}
                  placeholderAsLabel
                  useStyledInput
                  data-automation-id="middle-name-field"
                  disabled={readOnly}
                />
              </DeprecatedMultipartColumn>
              <DeprecatedMultipartColumn width={5}>
                <DeprecatedLastNameField
                  id="lastName"
                  name={`${fieldNamePrefix}lastName`}
                  placeholderAsLabel
                  useStyledInput
                  displayRequiredAsterisk={displayRequiredAsterisk}
                  data-automation-id="last-name-field"
                  disabled={readOnly}
                />
              </DeprecatedMultipartColumn>
            </DeprecatedMultipartRow>
            <FormGroupErrors
              fields={nameRowFields}
              groupClassName="CustomerSignerSubForm--FormGroup"
              errorClassName="CustomerSignerSubForm--ValidationMessage"
            />
          </FormGroup>
        </DeprecatedFormRow>

        <DeprecatedFormRow>
          <FormGroup
            className="CustomerSignerSubForm--FormGroup"
            errorClassName="CustomerSignerSubForm__validationFailed"
            fields={[`${fieldNamePrefix}email`]}
            disableFormRowStyle
          >
            <DeprecatedEmailField
              id="email"
              name={`${fieldNamePrefix}email`}
              data-automation-id="email-field"
              placeholderAsLabel
              useStyledInput
              displayRequiredAsterisk={displayRequiredAsterisk}
              disabled={readOnly}
            />
            <FormGroupErrors
              fields={[`${fieldNamePrefix}email`]}
              groupClassName="CustomerSignerSubForm--FormGroup"
              errorClassName="CustomerSignerSubForm--ValidationMessage"
            />
          </FormGroup>
        </DeprecatedFormRow>

        {showSignatoryCapacity && (
          <DeprecatedFormRow>
            <DeprecatedTextField
              id="signatoryTitle"
              name={`${fieldNamePrefix}signatoryTitle`}
              data-automation-id={`${fieldNamePrefix}-signatory-title`}
              placeholder="Signatory Capacity"
              placeholderAsLabel
              useStyledInput
              displayRequiredAsterisk
            />
            <FormGroupErrors
              fields={[`${fieldNamePrefix}signatoryTitle`]}
              groupClassName="CustomerSignerSubForm--FormGroup"
              errorClassName="CustomerSignerSubForm--ValidationMessage"
            />
          </DeprecatedFormRow>
        )}
      </div>
    );
  }
}

CustomerSignerSubForm.propTypes = {
  fieldNamePrefix: PropTypes.string,
  displayRequiredAsterisk: PropTypes.bool,
  readOnly: PropTypes.bool.isRequired,
  showSignatoryCapacity: PropTypes.bool.isRequired,
  showVestingTypeDropdown: PropTypes.bool,
};

export default injectIntl(CustomerSignerSubForm);
